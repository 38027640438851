import { makeAutoObservable } from "mobx";
export default class CommonStore {
    token = ''
    userInfo = {}
    constructor() {
        makeAutoObservable(this);
    }
    setToken = (token) => {
        this.token = token;
    };

    setUserInfo = (userInfo)=>{
        this.userInfo = userInfo
    }
}
