import ReactGA from "react-ga4";



const initAnalytics = (gId) =>{
  ReactGA.initialize(gId);
}


const setCurrentUserId = (tgId) =>{
  ReactGA.set({ userId:tgId});
}

// ReactGA.send("pageview");

const reportPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};
const reportCustomEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};



export {
  initAnalytics,
  setCurrentUserId,
  reportCustomEvent,
  reportPageView
};
