import stores from '../stores'
import Cookies from "js-cookie";

export const setToken = (token:string) => {
    stores.common.setToken(token);
    Cookies.set("token", token);
    window?.localStorage?.setItem("token", token);
};

export const getToken = () => {
    const _token = Cookies.get("token") || localStorage?.getItem("token") || stores.common.token;
    return !_token || _token === 'null' ? '' : _token
};


export const setCommonUserInfo = (userInfo:any) => {
    stores.common.setUserInfo(userInfo);
}

export const getCommonUserInfo = ():any=>{
    return stores.common.userInfo
}
