import React, {useEffect, useState} from 'react';
import { AppRoutes } from './route/app-routes';
import { BrowserRouter } from 'react-router-dom';
import '../src/static/css/base.scss';
import './App.scss'
import {MobXProviderContext, observer, inject} from 'mobx-react'
import stores from './stores'
import {getToken, setToken} from './utils/user'
import {getTokenApi} from './api/user'
import HSpinner from './components/spinner'
import {useToast} from './components/toast/toast'
import {initAnalytics, setCurrentUserId} from './utils/analytics'
import {TonConnectUIProvider} from '@tonconnect/ui-react'
function App() {
  const {store} = React.useContext(MobXProviderContext)
  const [isLoading,setIsLoading]=useState(true)
  const [isInTelegram,setIsInTelegram] = useState(true)
  useEffect(() => {
      if(process.env.REACT_APP_ENV!=='production'){
          initAnalytics('G-EJCNKWYR8L')//test
      }else{
          //initAnalytics('G-EJCNKWYR8L') //test
          initAnalytics('G-R01RET6M9V')
      }
      // window.Telegram = process.env.REACT_APP_ENV!=='production'?{
      //     WebApp:{
      //         initDataUnsafe:{
      //             user: {id: 7108866202, first_name: "Shawn", last_name: "", language_code: "zh-hans", allows_write_to_pm: true},hash: "6db3a1b113d4831cc7d2fa81098b1a1a481363bea47d08abfc578d78dc1d1c77",chat_type: "sender",chat_instance: "1613205082179435983",auth_date: "1726043758"
      //         },
      //         viewportStableHeight:0,
      //         expand:()=> {
      //
      //         },
      //         close:()=>{
      //
      //         },
      //         disableVerticalSwipes:()=>{
      //
      //         },
      //         CloudStorage:null
      //     }
      // }:
      //     {
      //         WebApp:{
      //             initDataUnsafe:{
      //                 user: {id: 7108866202, first_name: "Shawn", last_name: "", language_code: "zh-hans", allows_write_to_pm: true},hash: "81451520b014e96e487c418f6a9f6c67f4c3ef85c389e399c164f19a4f88a7f9",chat_type: "sender",chat_instance: "-3991196528343374968",auth_date: "1723533288"
      //             },
      //             viewportStableHeight:0,
      //             expand:()=> {
      //
      //             },
      //             close:()=>{
      //
      //             },
      //             disableVerticalSwipes:()=>{
      //
      //             },
      //             CloudStorage:null
      //         }
      //     }
    const tg = window.Telegram.WebApp;
    tg.expand();
    tg.disableVerticalSwipes()
    const tgInitData = tg.initDataUnsafe;
    window.viewportHeight = tg.viewportStableHeight;
    if (tgInitData.user) {
        if(process.env.REACT_APP_ENV!=='production'){
            setIsInTelegram(true)
        }else{
            if(window.navigator.userAgent.toLowerCase().indexOf('mobile')<0){
                setIsInTelegram(false)
                return
            }else{
                setIsInTelegram(true)
            }
        }
        getTokenApi(tgInitData)
            .then(res=>{
                if(res.code===0){
                    setCurrentUserId(tgInitData.user.id)
                    setIsLoading(false)
                }
            })
            .catch()
    }else{
        setIsInTelegram(false)
    }
    return () => {
      tg.close(); // Clean up when the component unmounts
    };
  }, []);

  if(!isInTelegram){
      return <div className={'mobile-div'}>
          <span>Play on your mobile</span>
          <li onClick={()=>{
              window.Telegram.WebApp.close()
              if( window.Telegram.WebApp.openTelegramLink){
                  window.Telegram.WebApp.openTelegramLink('https://t.me/SwagCoin1_Bot')
              }else{
                  window.open('https://t.me/SwagCoin1_Bot')
              }

          }}/>
          <span className={'bot-name'} onClick={()=>{
              window.Telegram.WebApp.close()
              if( window.Telegram.WebApp.openTelegramLink){
                  window.Telegram.WebApp.openTelegramLink('https://t.me/SwagCoin1_Bot')
              }else{
                  window.open('https://t.me/SwagCoin1_Bot')
              }
          }}>@SwagCoin1_Bot</span>
      </div>
  }

  if(isLoading){
    return (
        <div className={'app-loading-div'}>
            <HSpinner width={'32px'} height={'32px'} color={'#FEFEFE'}/>
        </div>
    )
  }
    return (
        <TonConnectUIProvider manifestUrl={'https://3hub.cc/tonconnect-manifest.json'}>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </TonConnectUIProvider>

    );
}
export default inject('store')(observer(App))
