import axios, {AxiosResponse} from "axios";
import {getToken} from "../utils/user";

export const baseURL = process.env.REACT_APP_ENV!=='production'?'https://staging.3hub.cc/tg_api':'https://3hub.cc/tg_api'
const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
});

axiosInstance.interceptors.request.use(
  function (config) {
    let token = getToken();
    config.headers.authorization = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance
