import React from 'react'
import './index.scss'
import classnames from 'classnames'
interface IProps {
  width?: string
  height?: string
  className?: any
  color?: any
}
const HSpinner = (props: IProps) => {
  const { className, width, height, color } = props
  return (
    <div
      className={classnames({
        spinnerWrap: true,
        [className]: className
      })}
    >
      <svg
        style={{
          width: width,
          height: height,
          color: color
        }}
        className="Progress-svg"
        viewBox="22 22 44 44"
      >
        <circle className="Progress-circle" cx="44" cy="44" r="19.2" fill="none" strokeWidth="6"></circle>
      </svg>
    </div>
  )
}
export default HSpinner
